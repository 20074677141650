var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "Create a new Team" },
      on: { cancel: _vm.close },
      model: {
        value: _vm.isVisible,
        callback: function ($$v) {
          _vm.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.close } }, [
            _vm._v("\n      Cancel\n    "),
          ]),
          _c(
            "a-button",
            {
              key: "submit",
              attrs: { type: "primary", loading: _vm.loading },
              on: { click: _vm.handleForm },
            },
            [_vm._v("\n      Create\n    ")]
          ),
        ],
        1
      ),
      _c(
        "a-form",
        { attrs: { form: _vm.form, layout: "vertical" } },
        [
          _c(
            "a-form-item",
            { attrs: { label: "Team Name" } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: [
                      "teamName",
                      {
                        rules: [
                          {
                            required: true,
                            message: "The team name is required",
                          },
                        ],
                      },
                    ],
                    expression:
                      "[\n          'teamName',\n          {\n            rules: [\n              {\n                required: true,\n                message: 'The team name is required'\n              }\n            ]\n          }\n        ]",
                  },
                ],
                attrs: { placeholder: "E.G Under 17s Soccer" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }